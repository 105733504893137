<script setup lang="ts">
/** @ts-expect-error */
import { useCheckoutState } from '@/Pages/Web/Components/Checkout/useCheckoutState.js'
import Checkout from '@/Pages/Web/Checkout.vue'

const { getProduct, setupCart } = useCheckout()
const { state, isReady } = useCheckoutState()

const route = useRoute()
const router = useRouter()

// We only fetch base details of the product on `/buy/:variant` visits
if (route.name === 'buy' || route.name === 'customQuery') {
  await getProduct()
} else {
  await setupCart()
}

useHead({
  title: state.value.pageTitle,
  meta: state.value.pageMeta,
})

// Make a setup request from the client side only
onNuxtReady(async () => {
  // Initialize `ls_customer` cookie
  useCustomerCookie()

  // This should only be called on `/buy/:variant` route
  if (!isReady.value) {
    await setupCart()

    router.replace('/checkout')
  }
})
</script>

<template>
  <div>
    <Checkout v-bind="state" cspNonce="" :is-initialized="isReady" :is-api="true" />
  </div>
</template>
