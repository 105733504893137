<template>
  <Menu as="div" class="menu">
    <MenuButton class="btn h-5 w-5 rounded bg-transparent p-1 hover:bg-light-97 dark:hover:bg-dark-19">
      <span class="h-3 w-3">
        <AvatarComponent
          :text="currentUser.name"
          :url="currentUser.avatar_url"
          :show-fallback="!currentUser.has_custom_avatar"
        />
      </span>
    </MenuButton>

    <MenuTransition>
      <MenuItems class="menu-items w-14">
        <MenuItem v-if="!isMyOrdersRoute && currentUser.has_orders">
          <a href="#" class="menu-item" @click.prevent="visitMyOrders()"> My Orders </a>
        </MenuItem>

        <MenuItem>
          <a href="#" class="menu-item" @click.prevent="visitAccount()">Account </a>
        </MenuItem>

        <MenuItem v-if="affiliateProfile && isMyOrdersRoute">
          <a :href="$route('affiliates-hub.dashboard')" class="menu-item">Affiliate hub</a>
        </MenuItem>

        <MenuItem>
          <a href="#" class="menu-item" @click.prevent="logout()"> Log Out</a>
        </MenuItem>
      </MenuItems>
    </MenuTransition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

import AvatarComponent from '@/Components/AvatarComponent.vue'
import MenuTransition from '@/Components/MenuTransition.vue'

export default {
  components: {
    AvatarComponent,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuTransition,
  },

  props: {
    isMyOrders: {
      type: Boolean,
      default: false,
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    accountSettingsRoute() {
      return this.isMyOrders ? this.$route('my-orders.account.settings') : this.$route('account.settings')
    },
    logoutRoute() {
      if (this.isCheckout) {
        return this.$route('store.logout', {
          domain: this.currentStore.url_domain,
        })
      }

      return this.$route('logout')
    },
    isMyOrdersRoute() {
      return this.isRoute(
        'my-orders',
        'my-orders.order',
        'my-orders.order-license-key',
        'my-orders.invoice',
        'my-orders.order-subscription',
        'my-orders.order-subscription-invoice',
        'my-orders.account.settings',
      )
    },
  },

  methods: {
    /**
     * The checkout is on a different domain, so we need to use direct links
     * (window.location) instead of AJAX requests. Otherwise we get CORS errors.
     */
    visitMyOrders() {
      this.isCheckout ? (window.location = this.$route('my-orders')) : this.$inertia.visit(this.$route('my-orders'))
    },
    visitAccount() {
      this.isCheckout ? (window.location = this.accountSettingsRoute) : this.$inertia.visit(this.accountSettingsRoute)
    },
    logout() {
      window.axios.post(this.logoutRoute, {}, { withCredentials: true }).then(() => {
        window.location = '/'
      })
    },
  },
}
</script>
