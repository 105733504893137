<script setup>
import { computed, inject } from 'vue'

import ButtonComponent from '@/Components/ButtonComponent.vue'
import SvgVue from '@/Components/SvgVue.vue'

import useCart from '../useCart.js'

const emit = defineEmits(['removeDiscount'])

const cart = useCart(inject('cart'))
const cartModel = cart.cart
const checkoutOptions = inject('checkoutOptions')

const props = defineProps({
  selectedPaymentMethod: String,
  country: String,
})

const activeDiscount = computed(() => {
  return cartModel.value.discount || null
})
const discountDescription = computed(() => {
  if (!activeDiscount.value) {
    return ''
  }

  if (activeDiscount.value.duration === 'repeating') {
    if (cart.priceModel.value.renewal_interval_unit === 'year') {
      let years = Math.ceil(activeDiscount.value.duration_in_months / 12)
      return years > 1 ? `Discount applies for ${years} years.` : 'Discount applies for the first year.'
    } else {
      let discountPeriod = activeDiscount.value.duration_in_months > 1 ? 'months' : 'month'
      return `Discount applies for ${activeDiscount.value.duration_in_months} ${discountPeriod}.`
    }
  }

  return `Discount applies ${activeDiscount.value.duration}.`
})
</script>

<template>
  <div>
    <div
      v-if="!cart.isLeadMagnetOrOneHundredPercentDiscount() && !cart.isSubscription()"
      v-show="props.selectedPaymentMethod === 'paypal'"
    >
      <div
        v-if="cartModel.currency !== 'USD' && !cart.isLeadMagnetOrOneHundredPercentDiscount()"
        class="px-3 text-center text-13 text-grey"
        :class="{
          'mt-2': checkoutOptions.embed,
          'mt-4': !checkoutOptions.embed,
        }"
      >
        You will be charged
        {{ formatCurrency(cartModel.total_in_usd, 'USD') }} USD.
      </div>
    </div>
    <div
      v-if="cart.isSubscription() && cart.emailIsConfirmed() && checkoutOptions.subscription_preview"
      class="px-3 text-center text-13 text-grey"
      :class="{
        'mt-2': checkoutOptions.embed,
        'mt-4': !checkoutOptions.embed,
      }"
    >
      {{ cartModel.charge_description }}

      <!-- nosemgrep. input doesn't contain user-generated HTML. -->
      <p v-if="discountDescription && !checkoutOptions.embed" v-html="discountDescription" />
    </div>
    <template v-if="checkoutOptions.embed">
      <div v-if="activeDiscount" class="mt-2 flex items-center justify-center text-13 text-grey">
        <template v-if="cart.isUsageBased()"> Discount applied </template>
        <template v-else>
          {{ formatCurrency(cartModel.discount_total, cartModel.currency) }}
          discount applied
        </template>
        <ButtonComponent
          v-if="checkoutOptions.discount"
          variant="plain"
          dusk="checkout-form-remove-discount"
          @click="emit('removeDiscount')"
        >
          <SvgVue icon="Cross Small" class="h-3 w-3 stroke-current" />
        </ButtonComponent>
      </div>
      <div v-if="cart.isSubscription() && discountDescription" class="text-center text-13 text-grey">
        <!-- nosemgrep. input doesn't contain user-generated HTML. -->
        <span v-html="discountDescription" />
      </div>
    </template>
    <div
      v-if="
        checkoutOptions.embed &&
        cartModel.tax &&
        !cart.isSubscription() &&
        !cart.isLeadMagnetOrOneHundredPercentDiscount()
      "
      class="mx-2 text-center text-13 text-grey"
      :class="{ 'mt-3': !activeDiscount, 'mt-1': activeDiscount }"
    >
      Payment includes
      {{ formatCurrency(cartModel.tax, cartModel.currency) }} for {{ props.country }}
      {{ cartModel.tax_name }}
    </div>
  </div>
</template>
