<template>
  <div>
    <div v-if="selectedVariant" class="mb-4">
      <div class="flex items-start">
        <h1 class="text-24 font-medium text-lemon-headings" dusk="checkout-product-name">
          <span>{{ product.name }}</span>
          <span v-if="!showVariants && isMultiVariant">&nbsp;({{ cartItem.variant.name }})</span>
        </h1>
        <p v-if="!showVariants && showMedia" class="ml-auto flex-shrink-0 text-lemon-secondary-text">
          <span class="ml-2 text-20">
            <template v-if="selectedVariant.priceModel.from_price">
              <span class="mr-0.5 text-13 text-wedges-gray">from</span>
              <span class="text-24 text-wedges-gray-900">{{ selectedVariant.priceModel.from_price }}</span>
            </template>
            <template v-else-if="displayPrice > 0">
              <span class="text-20">
                {{ formatCurrency(displayPrice, cart.currency) }}
              </span>
            </template>
            <template v-else-if="selectedVariant.priceModel.category === 'pwyw'">
              <span class="text-20">{{ formatCurrency(selectedVariant.priceModel.min_price, cart.currency) }}+</span>
            </template>
            <template v-else>
              <span class="text-20">FREE</span>
            </template>
          </span>
        </p>
      </div>
      <p v-if="!showVariants && !showMedia" class="mt-0.5 text-lemon-secondary-text">
        <template v-if="selectedVariant.priceModel.from_price">
          <span class="mr-0.5 text-13 text-lemon-secondary-text">from</span>
          <span class="text-24 text-lemon-headings">{{ selectedVariant.priceModel.from_price }}</span>
        </template>
        <template v-else-if="selectedVariant.priceModel.category === 'pwyw'">
          <span class="text-20">{{ formatCurrency(selectedVariant.priceModel.min_price, cart.currency) }}+</span>
        </template>
        <template v-else-if="displayPrice > 0">
          <span class="text-20">
            {{ formatCurrency(displayPrice, cart.currency) }}
          </span>
        </template>
        <template v-else>
          <span class="text-20">FREE</span>
        </template>
      </p>
      <p
        v-if="!showVariants && showVariantPriceLabel(selectedVariant)"
        class="mt-0.5 text-13 text-lemon-secondary-text"
      >
        {{ selectedVariant.priceModel.price_label }}
      </p>
      <div v-if="checkoutMorExperimentEnabled" class="flex items-center mt-1.5">
        <div v-if="checkoutOptions.logo" class="mr-1.5 h-3 w-3" dusk="checkout-logo">
          <StoreAvatar :store="store" :small="true" />
        </div>
        <p class="text-lemon-secondary-text">
          By <a :href="store.url" class="hover:underline">{{ store.name }}</a>
        </p>
      </div>
    </div>
    <CheckoutProductMedia
      v-if="showMedia"
      :product="product"
      :is-preview="isPreview"
      class="mb-4"
      dusk="checkout-product-media"
    />
    <div v-if="!showVariants && selectedVariant.priceModel.category === 'pwyw'" class="mb-3">
      <div class="flex items-center">
        <span class="mr-2 font-medium text-lemon-primary-text">Suggest a price:</span>
        <span class="flex-grow">
          <PriceInput
            v-model="pwywPrice[0]"
            :placeholder="selectedVariant.priceModel.suggested_price / 100"
            class="flex-grow"
            :disabled="disabled || !isInitialized"
            dusk="checkout-pwyw-price-input"
            @blur="emitChange"
          />
        </span>
      </div>
      <p v-if="pwywError" class="form-error">
        {{ pwywError }}
      </p>
    </div>
    <div v-if="showDescription">
      <div
        class="formatted-html text-lemon-primary-text"
        dusk="checkout-product-description"
        v-html="/** nosemgrep. input is sanitized with `CleanHtml` */ product.description"
      />
    </div>
    <div v-if="showVariants" class="mt-4">
      <a
        v-for="(variant, index) in publishedVariants"
        :key="variant.id"
        href="#"
        class="mb-3 block rounded border"
        :class="{
          'border-lemon-borders': variant.id !== selectedVariant.id,
          'border-lemon-active ring-1 ring-lemon-active': variant.id === selectedVariant.id,
        }"
        :dusk="`checkout-variant-${variant.id}`"
        @click.prevent="selectVariant(variant)"
      >
        <div class="flex items-center justify-between px-2 py-1">
          <div class="flex items-center">
            <div class="mr-1.5 flex-shrink-0">
              <input
                type="radio"
                class="form-radio pointer-events-none h-3 w-3"
                :checked="variant.id === selectedVariant.id"
              />
            </div>
            <h3 class="text-16 font-medium text-lemon-headings">
              {{ variant.name }}
            </h3>
          </div>
          <div class="ml-1.5 flex-shrink-0">
            <template v-if="variant.priceModel.from_price">
              <span class="mr-0.5 text-13 text-lemon-secondary-text">from</span>
              <span class="text-16 text-lemon-headings">{{ variant.priceModel.from_price }}</span>
            </template>
            <span v-else class="text-16 text-lemon-headings">
              <template v-if="variant.priceModel.category === 'pwyw'">
                {{ formatCurrency(variant.priceModel.min_price, cart.currency) }}+
              </template>
              <template v-else>
                {{ formatCurrency(variant.priceModel.unit_price, cart.currency) }}
              </template>
            </span>
          </div>
        </div>
        <div v-if="variant.priceModel.category === 'pwyw'" class="border-t border-lemon-borders px-3 py-1.5">
          <PriceInput
            v-model="pwywPrice[index]"
            :placeholder="variant.priceModel.suggested_price / 100"
            :disabled="disabled || !isInitialized"
            :dusk="`checkout-pwyw-price-input-${index}`"
            @blur="emitChange"
          >
            <template #prefix> Suggest a price: </template>
          </PriceInput>
          <p v-if="pwywError && variant.id === selectedVariant.id" class="form-error">
            {{ pwywError }}
          </p>
        </div>
        <div
          v-else-if="showVariantPriceLabel(variant)"
          class="border-t border-lemon-borders px-3 py-1.5 text-13 text-lemon-secondary-text"
        >
          {{ variant.priceModel.price_label }}
        </div>
        <div
          v-if="!descriptionIsEmpty(variant.description)"
          class="formatted-html border-t border-lemon-borders px-3 py-2 text-lemon-primary-text"
          v-html="/** nosemgrep. input is sanitized with `CleanHtml` */ variant.description"
        />
      </a>
    </div>
  </div>
</template>

<script>
import PriceInput from '@/Components/Forms/PriceInput.vue'
import StoreAvatar from '@/Components/StoreAvatar.vue'
import CheckoutProductMedia from '@/Pages/Web/Components/Checkout/CheckoutProductMedia.vue'

export default {
  components: {
    StoreAvatar,
    CheckoutProductMedia,
    PriceInput,
  },

  inject: ['isInitialized', 'isApi', 'checkoutMorExperimentEnabled'],

  props: {
    store: {
      type: Object,
      required: true,
    },
    cart: {
      type: Object,
      required: true,
    },
    cartItem: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    variants: {
      type: Array,
      required: true,
    },
    isMultiVariant: {
      type: Boolean,
      default: false,
    },
    checkoutOptions: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],

  data() {
    return {
      selectedVariant: null,
      prices: [],
      pwywError: null,
      pwywPrice: null,
    }
  },

  computed: {
    displayPrice() {
      if (!this.showVariants && this.selectedVariant.priceModel.category === 'pwyw') {
        return this.prices[0]
      }

      if (this.cart.checkout && this.cart.checkout.custom_price) {
        return this.cart.checkout.custom_price
      }

      return this.selectedVariant.price
    },
    showMedia() {
      return this.checkoutOptions.media && this.product.media.length
    },
    showDescription() {
      return !this.descriptionIsEmpty(this.product.description) && this.checkoutOptions.desc
    },
    publishedVariants() {
      let published = this.variants.filter((variant) => variant.status === 'published')

      if (published.length) {
        return published
      }

      return [this.variants[0]]
    },
    showVariants() {
      return this.publishedVariants.length > 1
    },
  },

  watch: {
    cartItem(value, oldValue) {
      if (value.id !== oldValue.id) {
        this.setSelectedVariantFromCartItem()
        this.setPrices()
      }
    },
    variants() {
      this.setPrices()
    },
    selectedVariant(newVal, oldVal) {
      if (oldVal && newVal.id !== oldVal.id) {
        this.pwywError = null
        this.emitChange()
      }
    },
    pwywPrice: {
      handler: function (newVal) {
        const index = this.publishedVariants.findIndex((variant) => variant.id === this.selectedVariant.id)
        this.prices[index] = newVal[index]
      },
      deep: true,
    },
  },

  created() {
    this.setSelectedVariantFromCartItem()
    this.setPrices()
    this.pwywPrice = this.publishedVariants.map(() => null)
  },

  methods: {
    setSelectedVariantFromCartItem() {
      this.selectedVariant = this.variants.find((variant) => variant.id === this.cartItem.variant_id)
    },
    setPrices() {
      this.prices = this.publishedVariants.map((variant) => {
        if (variant.id === this.cartItem.variant.id) {
          return this.cartItem.price
        }
        if (variant.priceModel.category === 'pwyw') {
          return variant.priceModel.min_price
        }

        return variant.priceModel.unit_price
      })
    },
    descriptionIsEmpty(description) {
      return !description || description === '<p></p>'
    },
    showVariantPriceLabel(variant) {
      if (!variant.priceModel.price_label) {
        return false
      }
      return variant.priceModel.from_price || variant.priceModel.category === 'subscription'
    },
    selectVariant(variant) {
      if (this.disabled || !this.isInitialized) {
        return
      }

      this.selectedVariant = variant
    },
    emitChange() {
      const index = this.publishedVariants.findIndex((variant) => variant.id === this.selectedVariant.id)
      const price = this.prices[index] ?? this.selectedVariant.price.min_price ?? 0

      if (this.selectedVariant.priceModel.category === 'pwyw' && price < this.selectedVariant.priceModel.min_price) {
        this.pwywError = `The minimum price is ${this.formatCurrency(this.selectedVariant.priceModel.min_price, this.cart.currency)}`
        return
      } else if (this.isApi) {
        this.pwywError = null
      } else {
        this.pwywError = this.$page.props.errors.price ?? null
      }

      this.$emit('change', {
        cart_item_id: this.cartItem.id,
        variant_id: this.selectedVariant.id,
        price: price,
      })
    },
  },
}
</script>
